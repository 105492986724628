import React from "react"
import Header from "../components/header"
import styles from "./about.module.scss"
import Layout from "../components/layout"

export default function About() {
  return (
    <Layout title="About | Good Behavior Films">
      {/* <Header />
      <div className={styles.aboutHero}>
        <p className={styles.heroContent}>
          Foundation 649 is a 501(c)(3)* that discovers and empowers young, talented
          Asian Americans who are actively shaping our cultural and social
          identity.
        </p>
      </div>
      <div className={styles.about}>
        <div className={styles.aboutSection}>
          <div className={styles.aboutSectionTitle}>Our Vision</div>
          <div className={styles.aboutSectionContent}>
            The political and social landscape of America is rapidly evolving.
            Asian Americans play an important part in shaping what the future of
            America looks like. By elevating and empowering young Asian American
            leaders, Foundation 649 aims to cement our place and our voice in
            our country.
          </div>
        </div>
        <div className={styles.aboutSection}>
          <div className={styles.aboutSectionTitle}>Our Name</div>
          <div className={styles.aboutSectionContent}>
            <strong>649</strong> is the case number of the 1898 Supreme Court
            case{" "}
            <a
              target="_blank"
              href="https://en.wikipedia.org/wiki/United_States_v._Wong_Kim_Ark"
            >
              "United States v. Wong Kim Ark, 169 US 649,"
            </a>{" "}
            which determined that the 14th Amendment to the US Constitution
            granted birthright citizenship to all persons born in the United
            States, regardless of race or ethnicity, a cornerstone of the
            establishment of Asians in America.
          </div>
        </div>
        <div className={styles.aboutSection}>
          <div className={styles.aboutSectionTitle}>Our Team</div>
          <div className={styles.aboutSectionContent}>
            We are an all volunteer team scattered across the U.S., with folks
            in California, New York, Georgia, and Texas. We come from a variety of
            backgrounds, ranging from media to technology to entertainment. We
            are unified but our interest in Asian American issues and elevating
            the Asian American identity. Get in touch with us at{" "}
            <a className={styles.link} href="mailto:team@foundation649.com">
              team@foundation649.com
            </a>
            !
          </div>
          <br/>
          <br/>
          * 501(c)(3) status pending.
        </div>
      </div> */}
    </Layout>
  )
}
